/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createWorkSummary = /* GraphQL */ `
  mutation CreateWorkSummary(
    $input: CreateWorkSummaryInput!
    $condition: ModelWorkSummaryConditionInput
  ) {
    createWorkSummary(input: $input, condition: $condition) {
      createdAt
      id
      coOwners
      owner
      updatedAt
      alias
      candidatePathId
      content
      leadershipPrinciples
      status
      statusReason
      submittedAt
      title
      type
      categories {
        items {
          id
          name
        }
      }
      documents {
        items {
          id
          fileRecord {
            id
            createdAt
            updatedAt
            owner
            fileName
            s3Key
            status
            fileSizeBytes
            fileDateModified
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      peerReviews {
        items {
          createdAt
          id
          coOwners
          updatedAt
          content
          reviewerAlias
          reviewer {
            name
          }
          reviewStatus
          reviewType
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateWorkSummary = /* GraphQL */ `
  mutation UpdateWorkSummary(
    $input: UpdateWorkSummaryInput!
    $condition: ModelWorkSummaryConditionInput
  ) {
    updateWorkSummary(input: $input, condition: $condition) {
      createdAt
      id
      coOwners
      updatedAt
      alias
      candidatePathId
      content
      leadershipPrinciples
      status
      statusReason
      submittedAt
      title
      type
      categories {
        items {
          id
          name
        }
      }
      documents {
        items {
          id
          fileRecord {
            id
            createdAt
            updatedAt
            owner
            fileName
            s3Key
            status
            fileSizeBytes
            fileDateModified
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      peerReviews {
        items {
          createdAt
          id
          coOwners
          updatedAt
          content
          reviewerAlias
          reviewer {
            name
          }
          reviewStatus
          reviewType
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteWorkSummary = /* GraphQL */ `
  mutation DeleteWorkSummary(
    $input: DeleteWorkSummaryInput!
    $condition: ModelWorkSummaryConditionInput
  ) {
    deleteWorkSummary(input: $input, condition: $condition) {
      id
      title
      alias
      _version
    }
  }
`;
export const createPeerReview = /* GraphQL */ `
  mutation CreatePeerReview(
    $input: CreatePeerReviewInput!
    $condition: ModelPeerReviewConditionInput
  ) {
    createPeerReview(input: $input, condition: $condition) {
      createdAt
      id
      coOwners
      updatedAt
      content
      requiredBy
      reviewerAlias
      reviewer {
        name
      }
      reviewedContentVersion
      reviewStatus
      reviewType
      candidatePath {
        id
        candidate
        manager
        name
        targetDate
        targetLevel
        template {
          id
          name
          targetLevel
        }
      }
      workSummary {
        id
        alias
        content
        title
        contentVersion
        type
        categories {
          items {
            id
            name
            description
          }
        }
        comments {
          items {
            id
          }
        }
        documents {
          items {
            id
            fileRecord {
              createdAt
              id
              owner
              updatedAt
              fileDateModified
              fileName
              fileSizeBytes
              status
              s3Key
              _deleted
            }
            _deleted
          }
        }
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updatePeerReview = /* GraphQL */ `
  mutation UpdatePeerReview(
    $input: UpdatePeerReviewInput!
    $condition: ModelPeerReviewConditionInput
  ) {
    updatePeerReview(input: $input, condition: $condition) {
      createdAt
      id
      coOwners
      updatedAt
      content
      requiredBy
      reviewerAlias
      reviewer {
        name
      }
      reviewedContentVersion
      reviewStatus
      reviewType
      candidatePath {
        id
        candidate
        manager
        name
        targetDate
        targetLevel
        template {
          id
          name
          targetLevel
        }
      }
      workSummary {
        id
        alias
        content
        title
        contentVersion
        type
        categories {
          items {
            id
            name
            description
          }
        }
        comments {
          items {
            id
          }
        }
        documents {
          items {
            id
            fileRecord {
              createdAt
              id
              owner
              updatedAt
              fileDateModified
              fileName
              fileSizeBytes
              status
              s3Key
              _deleted
            }
            _deleted
          }
        }
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deletePeerReview = /* GraphQL */ `
  mutation DeletePeerReview(
    $input: DeletePeerReviewInput!
    $condition: ModelPeerReviewConditionInput
  ) {
    deletePeerReview(input: $input, condition: $condition) {
      id
      reviewerAlias
      workSummary {
        title
      }
      _version
    }
  }
`;
export const createFileRecord = /* GraphQL */ `
  mutation CreateFileRecord(
    $input: CreateFileRecordInput!
    $condition: ModelFileRecordConditionInput
  ) {
    createFileRecord(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      owner
      fileName
      s3Key
      status
      fileSizeBytes
      fileDateModified
      workSummaries {
        items {
          id
          workSummaryID
        }
        nextToken
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateFileRecord = /* GraphQL */ `
  mutation UpdateFileRecord(
    $input: UpdateFileRecordInput!
    $condition: ModelFileRecordConditionInput
  ) {
    updateFileRecord(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      owner
      fileName
      s3Key
      status
      fileSizeBytes
      fileDateModified
      workSummaries {
        items {
          id
          workSummaryID
        }
        nextToken
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteFileRecord = /* GraphQL */ `
  mutation DeleteFileRecord(
    $input: DeleteFileRecordInput!
    $condition: ModelFileRecordConditionInput
  ) {
    deleteFileRecord(input: $input, condition: $condition) {
      id
      fileName
      owner
      _version
    }
  }
`;

export const createCandidatePath = /* GraphQL */ `
  mutation CreateCandidatePath(
    $input: CreatePromoPathInput!
    $condition: ModelPromoPathConditionInput
  ) {
    createPromoPath(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      categoryIds
      allowAttachments
      status
      name
      manager
      candidate
      role
      startLevel
      suggestedLPs
      targetLevel
      template {
        createdAt
        id
        updatedAt
        allowAttachments
        categoryIds
        name
        status
        role
        startLevel
        suggestedLPs
        targetLevel
        totalRequired
        categories {
          items {
            createdAt
            id
            updatedAt
            description
            isDefault
            name
            role
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
        }
        _version
        _deleted
        _lastChangedAt
      }
      categories {
        items {
          id
          createdAt
          updatedAt
          description
          isDefault
          name
          role
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const updateCandidatePath = /* GraphQL */ `
  mutation UpdateCandidatePath(
    $input: UpdatePromoPathInput!
    $condition: ModelPromoPathConditionInput
  ) {
    updatePromoPath(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      categoryIds
      allowAttachments
      status
      name
      manager
      candidate
      role
      startLevel
      suggestedLPs
      targetLevel
      template {
        createdAt
        id
        updatedAt
        allowAttachments
        categoryIds
        name
        status
        role
        startLevel
        suggestedLPs
        targetLevel
        totalRequired
        categories {
          items {
            createdAt
            id
            updatedAt
            description
            isDefault
            name
            role
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
        }
        _version
        _deleted
        _lastChangedAt
      }
      categories {
        items {
          id
          createdAt
          updatedAt
          description
          isDefault
          name
          role
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const createPromoPath = /* GraphQL */ `
  mutation CreatePromoPath(
    $input: CreatePromoPathInput!
    $condition: ModelPromoPathConditionInput
  ) {
    createPromoPath(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      categoryIds
      allowAttachments
      status
      name
      manager
      candidate

      role
      startLevel
      suggestedLPs
      targetLevel
      descendants {
        items {
          id
          candidate
          name
        }
        nextToken
      }
      categories {
        items {
          id
          createdAt
          updatedAt
          description
          isDefault
          name
          role
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updatePromoPath = /* GraphQL */ `
  mutation UpdatePromoPath(
    $input: UpdatePromoPathInput!
    $condition: ModelPromoPathConditionInput
  ) {
    updatePromoPath(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      categoryIds
      allowAttachments
      status
      name
      manager
      candidate
      role
      startLevel
      suggestedLPs
      targetLevel
      descendants {
        items {
          id
          candidate
          name
        }
        nextToken
      }
      categories {
        items {
          id
          createdAt
          updatedAt
          description
          isDefault
          name
          role
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteCandidatePath = /* GraphQL */ `
  mutation DeleteCandidatePath(
    $input: DeletePromoPathInput!
    $condition: ModelPromoPathConditionInput
  ) {
    deletePromoPath(input: $input, condition: $condition) {
      id
      name
      template {
        name
      }
      manager
      candidate
      _version
    }
  }
`;

export const deletePromoPath = /* GraphQL */ `
  mutation DeletePromoPath(
    $input: DeletePromoPathInput!
    $condition: ModelPromoPathConditionInput
  ) {
    deletePromoPath(input: $input, condition: $condition) {
      id
      name
      manager
      candidate
      _version
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      description
      isDefault
      name
      role
      status
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      description
      isDefault
      name
      role
      status
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      id
      name
      _version
    }
  }
`;
export const createUserProfile = /* GraphQL */ `
  mutation CreateUserProfile(
    $input: CreateUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    createUserProfile(input: $input, condition: $condition) {
      alias
      createdAt
      updatedAt
      personId
      email
      hasLoggedIn
      name
      jobTitle
      jobLevel
      manager
      managerProfile {
        alias
        email
        name
      }
      isManager
      orgName
      preferences {
        landingPage
        visualMode
        density
      }
      status
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateUserProfile = /* GraphQL */ `
  mutation UpdateUserProfile(
    $input: UpdateUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    updateUserProfile(input: $input, condition: $condition) {
      alias
      createdAt
      updatedAt
      personId
      email
      hasLoggedIn
      name
      jobTitle
      jobLevel
      manager
      managerProfile {
        alias
        email
        name
      }
      isManager
      orgName
      preferences {
        landingPage
        visualMode
        density
      }
      status
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteUserProfile = /* GraphQL */ `
  mutation DeleteUserProfile(
    $input: DeleteUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    deleteUserProfile(input: $input, condition: $condition) {
      alias
      _version
    }
  }
`;
export const createWorkSummaryFiles = /* GraphQL */ `
  mutation CreateWorkSummaryFiles(
    $input: CreateWorkSummaryFilesInput!
    $condition: ModelWorkSummaryFilesConditionInput
  ) {
    createWorkSummaryFiles(input: $input, condition: $condition) {
      id
      workSummaryID
      fileRecordID
      workSummary {
        id
        createdAt
        updatedAt
        owner
        coOwners
        alias
        categoryIds
        content
        leadershipPrinciples
        status
        statusReason
        submittedAt
        title
        type
        candidatePathId
        _version
        _deleted
        _lastChangedAt
      }
      fileRecord {
        id
        createdAt
        updatedAt
        owner
        fileName
        s3Key
        status
        fileSizeBytes
        fileDateModified
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateWorkSummaryFiles = /* GraphQL */ `
  mutation UpdateWorkSummaryFiles(
    $input: UpdateWorkSummaryFilesInput!
    $condition: ModelWorkSummaryFilesConditionInput
  ) {
    updateWorkSummaryFiles(input: $input, condition: $condition) {
      id
      workSummaryID
      fileRecordID
      workSummary {
        id
        createdAt
        updatedAt
        owner
        coOwners
        alias
        categoryIds
        content
        leadershipPrinciples
        status
        statusReason
        submittedAt
        title
        type
        candidatePathId
        _version
        _deleted
        _lastChangedAt
      }
      fileRecord {
        id
        createdAt
        updatedAt
        owner
        fileName
        s3Key
        status
        fileSizeBytes
        fileDateModified
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteWorkSummaryFiles = /* GraphQL */ `
  mutation DeleteWorkSummaryFiles(
    $input: DeleteWorkSummaryFilesInput!
    $condition: ModelWorkSummaryFilesConditionInput
  ) {
    deleteWorkSummaryFiles(input: $input, condition: $condition) {
      id
      workSummaryID
      fileRecordID
      _version
    }
  }
`;
export const createSharedUserFiles = /* GraphQL */ `
  mutation CreateSharedUserFiles(
    $input: CreateSharedUserFilesInput!
    $condition: ModelSharedUserFilesConditionInput
  ) {
    createSharedUserFiles(input: $input, condition: $condition) {
      id
      fileRecordID
      userProfileAlias
      fileRecord {
        id
        createdAt
        updatedAt
        owner
        fileName
        s3Key
        status
        fileSizeBytes
        fileDateModified
        _version
        _deleted
        _lastChangedAt
      }
      userProfile {
        alias
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateSharedUserFiles = /* GraphQL */ `
  mutation UpdateSharedUserFiles(
    $input: UpdateSharedUserFilesInput!
    $condition: ModelSharedUserFilesConditionInput
  ) {
    updateSharedUserFiles(input: $input, condition: $condition) {
      id
      fileRecordID
      userProfileAlias
      fileRecord {
        id
        createdAt
        updatedAt
        owner
        fileName
        s3Key
        status
        fileSizeBytes
        fileDateModified
        _version
        _deleted
        _lastChangedAt
      }
      userProfile {
        alias
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteSharedUserFiles = /* GraphQL */ `
  mutation DeleteSharedUserFiles(
    $input: DeleteSharedUserFilesInput!
    $condition: ModelSharedUserFilesConditionInput
  ) {
    deleteSharedUserFiles(input: $input, condition: $condition) {
      id
      fileRecordID
      userProfileAlias
      _version
    }
  }
`;

export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      createdAt
      id
      owner
      updatedAt
      type
      content
      workSummaryId
      author {
        alias
        name
      }
      status
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      createdAt
      id
      owner
      updatedAt
      type
      content
      workSummaryId
      author {
        alias
        name
      }
      status
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      _version
      workSummaryId
    }
  }
`;
export const createAuditTrails = /* GraphQL */ `
  mutation CreateAuditTrails(
    $input: CreateAuditTrailsInput!
    $condition: ModelAuditTrailsConditionInput
  ) {
    createAuditTrails(input: $input, condition: $condition) {
      id
      modifiedBy
      changeJustification
      recordId
      recordType
      rawOldRecord
      rawNewRecord
      humanReadableLog
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateAuditTrails = /* GraphQL */ `
  mutation UpdateAuditTrails(
    $input: UpdateAuditTrailsInput!
    $condition: ModelAuditTrailsConditionInput
  ) {
    updateAuditTrails(input: $input, condition: $condition) {
      id
      modifiedBy
      changeJustification
      recordId
      recordType
      rawOldRecord
      rawNewRecord
      humanReadableLog
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAuditTrails = /* GraphQL */ `
  mutation DeleteAuditTrails(
    $input: DeleteAuditTrailsInput!
    $condition: ModelAuditTrailsConditionInput
  ) {
    deleteAuditTrails(input: $input, condition: $condition) {
      id
      modifiedBy
      changeJustification
      recordId
      recordType
      rawOldRecord
      rawNewRecord
      humanReadableLog
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createPromoReadinessRecord = /* GraphQL */
  `mutation CreatePromoReadinessRecord(
    $input: CreatePromoReadinessRecordInput!
    $condition: ModelPromoReadinessRecordConditionInput
  ) {
    createPromoReadinessRecord(input: $input, condition: $condition) {
      alias
      createdAt
      updatedAt
      id
      certList
      techPillar
      tfcMembership
      rating
      feedbackProviders
      otherContributions
      _version
      _deleted
      _lastChangedAt
      lastModifiedBy
      owner
      __typename
    }
  }`;

  export const updatePromoReadinessRecord = /* GraphQL */
    `mutation UpdatePromoReadinessRecord(
      $input: UpdatePromoReadinessRecordInput!
      $condition: ModelPromoReadinessRecordConditionInput
    ) {
      updatePromoReadinessRecord(input: $input, condition: $condition) {
        alias
        createdAt
        updatedAt
        id
        certList
        techPillar
        tfcMembership
        rating
        feedbackProviders
        otherContributions
        _version
        _deleted
        _lastChangedAt
        lastModifiedBy
        owner
        __typename
      }
    }
    `;
  export const deletePromoReadinessRecord = /* GraphQL */
    `mutation DeletePromoReadinessRecord(
      $input: DeletePromoReadinessRecordInput!
      $condition: ModelPromoReadinessRecordConditionInput
    ) {
      deletePromoReadinessRecord(input: $input, condition: $condition) {
        alias
        createdAt
        updatedAt
        id
        certList
        techPillar
        tfcMembership
        rating
        feedbackProviders
        otherContributions
        _version
        _deleted
        _lastChangedAt
        lastModifiedBy
        owner
        __typename
      }
    }
    `
    export const createDocumentReviewSessionRecord = /* GraphQL */ `mutation CreateDocumentReviewSessionRecord(
      $input: CreateDocumentReviewSessionRecordInput!
      $condition: ModelDocumentReviewSessionRecordConditionInput
    ) {
      createDocumentReviewSessionRecord(input: $input, condition: $condition) {
        id
        createdAt
        updatedAt
        ownerAlias
        candidateAlias
        workdocsLink
        chimeLink
        sessionStart
        sessionState
        documentReviewers {
          items {
            id
            documentReviewSessionRecordID
            calibratedDocumentReviewerRecordID
            documentReviewSessionRecord {
              id
              createdAt
              updatedAt
              ownerAlias
              candidateAlias
              workdocsLink
              chimeLink
              sessionStart
              sessionState
              documentReviewers {
                items {
                  id
                  documentReviewSessionRecordID
                  calibratedDocumentReviewerRecordID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  owner
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              _version
              _deleted
              _lastChangedAt
              lastModifiedBy
              owner
              __typename
            }
            calibratedDocumentReviewerRecord {
              id
              createdAt
              updatedAt
              alias
              completedSessions
              documentReviews {
                items {
                  id
                  documentReviewSessionRecordID
                  calibratedDocumentReviewerRecordID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  owner
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              _version
              _deleted
              _lastChangedAt
              lastModifiedBy
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        lastModifiedBy
        owner
        __typename
      }
    }
    `;
    export const updateDocumentReviewSessionRecord = /* GraphQL */ `mutation UpdateDocumentReviewSessionRecord(
      $input: UpdateDocumentReviewSessionRecordInput!
      $condition: ModelDocumentReviewSessionRecordConditionInput
    ) {
      updateDocumentReviewSessionRecord(input: $input, condition: $condition) {
        id
        createdAt
        updatedAt
        ownerAlias
        candidateAlias
        workdocsLink
        chimeLink
        sessionStart
        sessionState
        documentReviewers {
          items {
            id
            documentReviewSessionRecordID
            calibratedDocumentReviewerRecordID
            documentReviewSessionRecord {
              id
              createdAt
              updatedAt
              ownerAlias
              candidateAlias
              workdocsLink
              chimeLink
              sessionStart
              sessionState
              documentReviewers {
                items {
                  id
                  documentReviewSessionRecordID
                  calibratedDocumentReviewerRecordID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  owner
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              _version
              _deleted
              _lastChangedAt
              lastModifiedBy
              owner
              __typename
            }
            calibratedDocumentReviewerRecord {
              id
              createdAt
              updatedAt
              alias
              completedSessions
              documentReviews {
                items {
                  id
                  documentReviewSessionRecordID
                  calibratedDocumentReviewerRecordID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  owner
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              _version
              _deleted
              _lastChangedAt
              lastModifiedBy
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        lastModifiedBy
        owner
        __typename
      }
    }
    `;
    export const deleteDocumentReviewSessionRecord = /* GraphQL */ `mutation DeleteDocumentReviewSessionRecord(
      $input: DeleteDocumentReviewSessionRecordInput!
      $condition: ModelDocumentReviewSessionRecordConditionInput
    ) {
      deleteDocumentReviewSessionRecord(input: $input, condition: $condition) {
        id
        createdAt
        updatedAt
        ownerAlias
        candidateAlias
        workdocsLink
        chimeLink
        sessionStart
        sessionState
        documentReviewers {
          items {
            id
            documentReviewSessionRecordID
            calibratedDocumentReviewerRecordID
            documentReviewSessionRecord {
              id
              createdAt
              updatedAt
              ownerAlias
              candidateAlias
              workdocsLink
              chimeLink
              sessionStart
              sessionState
              documentReviewers {
                items {
                  id
                  documentReviewSessionRecordID
                  calibratedDocumentReviewerRecordID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  owner
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              _version
              _deleted
              _lastChangedAt
              lastModifiedBy
              owner
              __typename
            }
            calibratedDocumentReviewerRecord {
              id
              createdAt
              updatedAt
              alias
              completedSessions
              documentReviews {
                items {
                  id
                  documentReviewSessionRecordID
                  calibratedDocumentReviewerRecordID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  owner
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              _version
              _deleted
              _lastChangedAt
              lastModifiedBy
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        lastModifiedBy
        owner
        __typename
      }
    }
    `;
    export const createCalibratedDocumentReviewerRecord = /* GraphQL */ `mutation CreateCalibratedDocumentReviewerRecord(
      $input: CreateCalibratedDocumentReviewerRecordInput!
      $condition: ModelCalibratedDocumentReviewerRecordConditionInput
    ) {
      createCalibratedDocumentReviewerRecord(input: $input, condition: $condition) {
        id
        createdAt
        updatedAt
        alias
        completedSessions
        documentReviews {
          items {
            id
            documentReviewSessionRecordID
            calibratedDocumentReviewerRecordID
            documentReviewSessionRecord {
              id
              createdAt
              updatedAt
              ownerAlias
              candidateAlias
              workdocsLink
              chimeLink
              sessionStart
              sessionState
              documentReviewers {
                items {
                  id
                  documentReviewSessionRecordID
                  calibratedDocumentReviewerRecordID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  owner
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              _version
              _deleted
              _lastChangedAt
              lastModifiedBy
              owner
              __typename
            }
            calibratedDocumentReviewerRecord {
              id
              createdAt
              updatedAt
              alias
              completedSessions
              documentReviews {
                items {
                  id
                  documentReviewSessionRecordID
                  calibratedDocumentReviewerRecordID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  owner
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              _version
              _deleted
              _lastChangedAt
              lastModifiedBy
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        lastModifiedBy
        owner
        __typename
      }
    }
    `;
    export const updateCalibratedDocumentReviewerRecord = /* GraphQL */ `mutation UpdateCalibratedDocumentReviewerRecord(
      $input: UpdateCalibratedDocumentReviewerRecordInput!
      $condition: ModelCalibratedDocumentReviewerRecordConditionInput
    ) {
      updateCalibratedDocumentReviewerRecord(input: $input, condition: $condition) {
        id
        createdAt
        updatedAt
        alias
        completedSessions
        documentReviews {
          items {
            id
            documentReviewSessionRecordID
            calibratedDocumentReviewerRecordID
            documentReviewSessionRecord {
              id
              createdAt
              updatedAt
              ownerAlias
              candidateAlias
              workdocsLink
              chimeLink
              sessionStart
              sessionState
              documentReviewers {
                items {
                  id
                  documentReviewSessionRecordID
                  calibratedDocumentReviewerRecordID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  owner
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              _version
              _deleted
              _lastChangedAt
              lastModifiedBy
              owner
              __typename
            }
            calibratedDocumentReviewerRecord {
              id
              createdAt
              updatedAt
              alias
              completedSessions
              documentReviews {
                items {
                  id
                  documentReviewSessionRecordID
                  calibratedDocumentReviewerRecordID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  owner
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              _version
              _deleted
              _lastChangedAt
              lastModifiedBy
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        lastModifiedBy
        owner
        __typename
      }
    }
    `;
    export const deleteCalibratedDocumentReviewerRecord = /* GraphQL */ `mutation DeleteCalibratedDocumentReviewerRecord(
      $input: DeleteCalibratedDocumentReviewerRecordInput!
      $condition: ModelCalibratedDocumentReviewerRecordConditionInput
    ) {
      deleteCalibratedDocumentReviewerRecord(input: $input, condition: $condition) {
        id
        createdAt
        updatedAt
        alias
        completedSessions
        documentReviews {
          items {
            id
            documentReviewSessionRecordID
            calibratedDocumentReviewerRecordID
            documentReviewSessionRecord {
              id
              createdAt
              updatedAt
              ownerAlias
              candidateAlias
              workdocsLink
              chimeLink
              sessionStart
              sessionState
              documentReviewers {
                items {
                  id
                  documentReviewSessionRecordID
                  calibratedDocumentReviewerRecordID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  owner
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              _version
              _deleted
              _lastChangedAt
              lastModifiedBy
              owner
              __typename
            }
            calibratedDocumentReviewerRecord {
              id
              createdAt
              updatedAt
              alias
              completedSessions
              documentReviews {
                items {
                  id
                  documentReviewSessionRecordID
                  calibratedDocumentReviewerRecordID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  owner
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              _version
              _deleted
              _lastChangedAt
              lastModifiedBy
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        lastModifiedBy
        owner
        __typename
      }
    }
    `;
    export const createPanelReviewRecord = /* GraphQL */ `mutation CreatePanelReviewRecord(
      $input: CreatePanelReviewRecordInput!
      $condition: ModelPanelReviewRecordConditionInput
    ) {
      createPanelReviewRecord(input: $input, condition: $condition) {
        id
        createdAt
        updatedAt
        candidateAlias
        workdocsLink
        chimeLink
        sessionStart
        sessionState
        _version
        _deleted
        _lastChangedAt
        lastModifiedBy
        owner
        __typename
      }
    }
    `;
    export const updatePanelReviewRecord = /* GraphQL */ `mutation UpdatePanelReviewRecord(
      $input: UpdatePanelReviewRecordInput!
      $condition: ModelPanelReviewRecordConditionInput
    ) {
      updatePanelReviewRecord(input: $input, condition: $condition) {
        id
        createdAt
        updatedAt
        candidateAlias
        workdocsLink
        chimeLink
        sessionStart
        sessionState
        _version
        _deleted
        _lastChangedAt
        lastModifiedBy
        owner
        __typename
      }
    }
    `;
    export const deletePanelReviewRecord = /* GraphQL */ `mutation DeletePanelReviewRecord(
      $input: DeletePanelReviewRecordInput!
      $condition: ModelPanelReviewRecordConditionInput
    ) {
      deletePanelReviewRecord(input: $input, condition: $condition) {
        id
        createdAt
        updatedAt
        candidateAlias
        workdocsLink
        chimeLink
        sessionStart
        sessionState
        _version
        _deleted
        _lastChangedAt
        lastModifiedBy
        owner
        __typename
      }
    }
    `;
    export const createCalibratedPanelReviewerRecord = /* GraphQL */ `mutation CreateCalibratedPanelReviewerRecord(
      $input: CreateCalibratedPanelReviewerRecordInput!
      $condition: ModelCalibratedPanelReviewerRecordConditionInput
    ) {
      createCalibratedPanelReviewerRecord(input: $input, condition: $condition) {
        createdAt
        updatedAt
        candidateAlias
        completedSessions
        _version
        _deleted
        _lastChangedAt
        lastModifiedBy
        owner
        __typename
      }
    }
    `;
    export const updateCalibratedPanelReviewerRecord = /* GraphQL */ `mutation UpdateCalibratedPanelReviewerRecord(
      $input: UpdateCalibratedPanelReviewerRecordInput!
      $condition: ModelCalibratedPanelReviewerRecordConditionInput
    ) {
      updateCalibratedPanelReviewerRecord(input: $input, condition: $condition) {
        createdAt
        updatedAt
        candidateAlias
        completedSessions
        _version
        _deleted
        _lastChangedAt
        lastModifiedBy
        owner
        __typename
      }
    }
    `;
    export const deleteCalibratedPanelReviewerRecord = /* GraphQL */ `mutation DeleteCalibratedPanelReviewerRecord(
      $input: DeleteCalibratedPanelReviewerRecordInput!
      $condition: ModelCalibratedPanelReviewerRecordConditionInput
    ) {
      deleteCalibratedPanelReviewerRecord(input: $input, condition: $condition) {
        createdAt
        updatedAt
        candidateAlias
        completedSessions
        _version
        _deleted
        _lastChangedAt
        lastModifiedBy
        owner
        __typename
      }
    }
    `;
    export const createDocumentReviewSessionToReviewerMapping = /* GraphQL */ `mutation CreateDocumentReviewSessionToReviewerMapping(
      $input: CreateDocumentReviewSessionToReviewerMappingInput!
      $condition: ModelDocumentReviewSessionToReviewerMappingConditionInput
    ) {
      createDocumentReviewSessionToReviewerMapping(
        input: $input
        condition: $condition
      ) {
        id
        documentReviewSessionRecordID
        calibratedDocumentReviewerRecordID
        documentReviewSessionRecord {
          id
          createdAt
          updatedAt
          ownerAlias
          candidateAlias
          workdocsLink
          chimeLink
          sessionStart
          sessionState
          documentReviewers {
            items {
              id
              documentReviewSessionRecordID
              calibratedDocumentReviewerRecordID
              documentReviewSessionRecord {
                id
                createdAt
                updatedAt
                ownerAlias
                candidateAlias
                workdocsLink
                chimeLink
                sessionStart
                sessionState
                documentReviewers {
                  nextToken
                  startedAt
                  __typename
                }
                _version
                _deleted
                _lastChangedAt
                lastModifiedBy
                owner
                __typename
              }
              calibratedDocumentReviewerRecord {
                id
                createdAt
                updatedAt
                alias
                completedSessions
                documentReviews {
                  nextToken
                  startedAt
                  __typename
                }
                _version
                _deleted
                _lastChangedAt
                lastModifiedBy
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          lastModifiedBy
          owner
          __typename
        }
        calibratedDocumentReviewerRecord {
          id
          createdAt
          updatedAt
          alias
          completedSessions
          documentReviews {
            items {
              id
              documentReviewSessionRecordID
              calibratedDocumentReviewerRecordID
              documentReviewSessionRecord {
                id
                createdAt
                updatedAt
                ownerAlias
                candidateAlias
                workdocsLink
                chimeLink
                sessionStart
                sessionState
                documentReviewers {
                  nextToken
                  startedAt
                  __typename
                }
                _version
                _deleted
                _lastChangedAt
                lastModifiedBy
                owner
                __typename
              }
              calibratedDocumentReviewerRecord {
                id
                createdAt
                updatedAt
                alias
                completedSessions
                documentReviews {
                  nextToken
                  startedAt
                  __typename
                }
                _version
                _deleted
                _lastChangedAt
                lastModifiedBy
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          lastModifiedBy
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
    }
    `;
    export const updateDocumentReviewSessionToReviewerMapping = /* GraphQL */ `mutation UpdateDocumentReviewSessionToReviewerMapping(
      $input: UpdateDocumentReviewSessionToReviewerMappingInput!
      $condition: ModelDocumentReviewSessionToReviewerMappingConditionInput
    ) {
      updateDocumentReviewSessionToReviewerMapping(
        input: $input
        condition: $condition
      ) {
        id
        documentReviewSessionRecordID
        calibratedDocumentReviewerRecordID
        documentReviewSessionRecord {
          id
          createdAt
          updatedAt
          ownerAlias
          candidateAlias
          workdocsLink
          chimeLink
          sessionStart
          sessionState
          documentReviewers {
            items {
              id
              documentReviewSessionRecordID
              calibratedDocumentReviewerRecordID
              documentReviewSessionRecord {
                id
                createdAt
                updatedAt
                ownerAlias
                candidateAlias
                workdocsLink
                chimeLink
                sessionStart
                sessionState
                documentReviewers {
                  nextToken
                  startedAt
                  __typename
                }
                _version
                _deleted
                _lastChangedAt
                lastModifiedBy
                owner
                __typename
              }
              calibratedDocumentReviewerRecord {
                id
                createdAt
                updatedAt
                alias
                completedSessions
                documentReviews {
                  nextToken
                  startedAt
                  __typename
                }
                _version
                _deleted
                _lastChangedAt
                lastModifiedBy
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          lastModifiedBy
          owner
          __typename
        }
        calibratedDocumentReviewerRecord {
          id
          createdAt
          updatedAt
          alias
          completedSessions
          documentReviews {
            items {
              id
              documentReviewSessionRecordID
              calibratedDocumentReviewerRecordID
              documentReviewSessionRecord {
                id
                createdAt
                updatedAt
                ownerAlias
                candidateAlias
                workdocsLink
                chimeLink
                sessionStart
                sessionState
                documentReviewers {
                  nextToken
                  startedAt
                  __typename
                }
                _version
                _deleted
                _lastChangedAt
                lastModifiedBy
                owner
                __typename
              }
              calibratedDocumentReviewerRecord {
                id
                createdAt
                updatedAt
                alias
                completedSessions
                documentReviews {
                  nextToken
                  startedAt
                  __typename
                }
                _version
                _deleted
                _lastChangedAt
                lastModifiedBy
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          lastModifiedBy
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
    }
    `;
    export const deleteDocumentReviewSessionToReviewerMapping = /* GraphQL */ `mutation DeleteDocumentReviewSessionToReviewerMapping(
      $input: DeleteDocumentReviewSessionToReviewerMappingInput!
      $condition: ModelDocumentReviewSessionToReviewerMappingConditionInput
    ) {
      deleteDocumentReviewSessionToReviewerMapping(
        input: $input
        condition: $condition
      ) {
        id
        documentReviewSessionRecordID
        calibratedDocumentReviewerRecordID
        documentReviewSessionRecord {
          id
          createdAt
          updatedAt
          ownerAlias
          candidateAlias
          workdocsLink
          chimeLink
          sessionStart
          sessionState
          documentReviewers {
            items {
              id
              documentReviewSessionRecordID
              calibratedDocumentReviewerRecordID
              documentReviewSessionRecord {
                id
                createdAt
                updatedAt
                ownerAlias
                candidateAlias
                workdocsLink
                chimeLink
                sessionStart
                sessionState
                documentReviewers {
                  nextToken
                  startedAt
                  __typename
                }
                _version
                _deleted
                _lastChangedAt
                lastModifiedBy
                owner
                __typename
              }
              calibratedDocumentReviewerRecord {
                id
                createdAt
                updatedAt
                alias
                completedSessions
                documentReviews {
                  nextToken
                  startedAt
                  __typename
                }
                _version
                _deleted
                _lastChangedAt
                lastModifiedBy
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          lastModifiedBy
          owner
          __typename
        }
        calibratedDocumentReviewerRecord {
          id
          createdAt
          updatedAt
          alias
          completedSessions
          documentReviews {
            items {
              id
              documentReviewSessionRecordID
              calibratedDocumentReviewerRecordID
              documentReviewSessionRecord {
                id
                createdAt
                updatedAt
                ownerAlias
                candidateAlias
                workdocsLink
                chimeLink
                sessionStart
                sessionState
                documentReviewers {
                  nextToken
                  startedAt
                  __typename
                }
                _version
                _deleted
                _lastChangedAt
                lastModifiedBy
                owner
                __typename
              }
              calibratedDocumentReviewerRecord {
                id
                createdAt
                updatedAt
                alias
                completedSessions
                documentReviews {
                  nextToken
                  startedAt
                  __typename
                }
                _version
                _deleted
                _lastChangedAt
                lastModifiedBy
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          lastModifiedBy
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
    }
    `;
