/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDirectReportsExternal = /* GraphQL */ `
  query GetDirectReportsExternal($alias: String!) {
    getDirectReports(alias: $alias) {
      alias
      directReports {
        alias
        hireDate
        personId
        jobLevel
        isManager
        firstName
        lastName
        email
      }
    }
  }
`;
export const getPapiDirectReports = /* GraphQL */
`query GetPapiDirectReports($alias: String!) {
  getPapiDirectReports(alias: $alias) {
    alias
    directReports {
      username
      personId
      jobLevel
      isManager
      firstName
      lastName
      email
      hrJobTitle
      __typename
    }
    __typename
  }
}`
export const getPromoReadinessRecord = /* GraphQL */ `
  query getPromoReadinessRecord($alias: String!) {
    getPromoReadinessRecord(alias: $alias) {
      alias
      createdAt
      updatedAt
      id
      certList
      techPillar
      tfcMembership
      rating
      feedbackProviders
      otherContributions
      _version
      _deleted
      _lastChangedAt
      lastModifiedBy
      owner
    }
  }
`;

export const getDirectReports = /* GraphQL */ `
  query GetDirectReports(
    $manager: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfilesByManager(
      manager: $manager
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        alias
        email
        hireDate
        isManager
        jobLevel
        name
        _deleted
        _version
      }
      nextToken
    }
  }
`;

export const getUser = /* GraphQL */ `
  query GetUser($alias: String!) {
    getUser(alias: $alias) {
      alias
      jobLevel
      hrJobTitle
      isManager
      firstName
      lastName
      email
      managerAlias
      managerName
    }
  }
`;

export const getPeopleprofile = /* GraphQL */ `
  query getPeopleProfile($alias: String!) {
    getPeopleprofile(alias: $alias) {
      username
      personId
      firstName
      lastName
      jobLevel
      job {
        hireDate
        lastHireDate
        costCenterEntryDate
        jobEntryDate
        departmentEntryDate
      }
      emailAddress
      isManager
      businessTitle
      jobFamilyName
      manager
      skipManager
      employeeId
      costCenterId
      lastHireDate
      location {
        city
        name
      }
    }
  }
`;

export const findUser = /* GraphQL */ `
  query FindUser($alias: String!) {
    findUser(alias: $alias) {
      alias
      jobLevel
      hrJobTitle
      isManager
      firstName
      lastName
      email
      managerAlias
      managerName
    }
  }
`;

export const getFileDownloadUrl = /* GraphQL */ `
  query GetFileDownloadUrl($referenceId: ID, $fileRecordType: FileRecordType, $fileRecordId: ID) {
    getFileDownloadUrl(referenceId: $referenceId, fileRecordType: $fileRecordType, fileRecordId: $fileRecordId) {
      referenceId
      fileRecordType
      fileRecordId
      presignedURL
    }
  }
`;

export const getWorkSummary = /* GraphQL */ `
  query GetWorkSummary(
    $id: ID!
    $peerReviewFilter: ModelPeerReviewFilterInput
  ) {
    getWorkSummary(id: $id) {
      createdAt
      id
      coOwners
      updatedAt
      alias
      candidatePathId
      content
      contentVersion
      leadershipPrinciples
      status
      statusReason
      submittedAt
      title
      type
      categories {
        items {
          id
          name
        }
      }
      documents {
        items {
          id
          fileRecord {
            createdAt
            id
            owner
            updatedAt
            fileDateModified
            fileName
            fileSizeBytes
            status
            s3Key
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      peerReviews(filter: $peerReviewFilter) {
        items {
          createdAt
          id
          coOwners
          updatedAt
          reviewerAlias
          reviewer {
            name
          }
          reviewStatus
          reviewType
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const listWorkSummariesByAlias = /* GraphQL */ `
  query ListWorkSummariesByAlias(
    $alias: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWorkSummaryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkSummariesByAlias(
      alias: $alias
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        id
        coOwners
        updatedAt
        alias
        candidatePathId
        leadershipPrinciples
        status
        statusReason
        submittedAt
        title
        type
        categories {
          items {
            id
            name
          }
        }
        comments(
          typeStatus: { eq: { type: CANDIDATE, status: ACTIVE } }
          limit: 1000
        ) {
          items {
            id
          }
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
    }
  }
`;

export const listWorkSummariesByAliasAdmin = /* GraphQL */ `
  query ListWorkSummariesByAliasAdmin(
    $alias: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWorkSummaryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkSummariesByAlias(
      alias: $alias
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        id
        coOwners
        updatedAt
        alias
        candidatePathId
        status
        statusReason
        submittedAt
        title
        type
        categories {
          items {
            id
            name
          }
        }
        peerReviews {
          items {
            id
            reviewerAlias
            _version
            _deleted
            _lastChangedAt
          }
        nextToken
        }
        candidatePath {
          id
          name
          manager
          _version
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
    }
  }
`;

export const listWorkSummariesByAliasAndCategory = /* GraphQL */ `
  query ListWorkSummariesByAliasAndCategory(
    $alias: String!
    $categoryId: ID!
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    listWorkSummariesByAlias(
      alias: $alias
      sortDirection: $sortDirection
      filter: { categoryIds: { contains: $categoryId } }
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        id
        coOwners
        updatedAt
        alias
        candidatePathId
        leadershipPrinciples
        status
        statusReason
        submittedAt
        title
        type
        categories {
          items {
            id
            name
            status
          }
        }
        comments(
          typeStatus: { eq: { type: CANDIDATE, status: ACTIVE } }
          limit: 1000
        ) {
          items {
            id
          }
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
    }
  }
`;

export const listWorkSummariesByCandidatePath = /* GraphQL */ `
  query ListWorkSummariesByCandidatePath(
    $candidatePathId: ID!
    $submittedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWorkSummaryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkSummariesByCandidatePath(
      candidatePathId: $candidatePathId
      submittedAt: $submittedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        id
        coOwners
        updatedAt
        alias
        candidatePathId
        leadershipPrinciples
        status
        statusReason
        submittedAt
        title
        type
        categories {
          items {
            id
            name
          }
        }
        comments(
          typeStatus: { eq: { type: CANDIDATE, status: ACTIVE } }
          limit: 1000
        ) {
          items {
            id
          }
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
    }
  }
`;

export const getFileInfo = /* GraphQL */ `
  query GetFileInfo($id: ID!) {
    getFileRecord(id: $id) {
      createdAt
      id
      owner
      updatedAt
      fileDateModified
      fileName
      fileSizeBytes
      status
      s3Key
      storageIdKey
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const getFileRecord = /* GraphQL */ `
  query GetFileRecord($id: ID!) {
    getFileRecord(id: $id) {
      createdAt
      id
      owner
      updatedAt
      fileDateModified
      fileName
      fileSizeBytes
      status
      s3Key
      storageIdKey
      workSummaries {
        items {
          id
          workSummaryID
          workSummary {
            title
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      users {
        items {
          id
          userProfileAlias
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const listFilesByOwner = /* GraphQL */ `
  query ListFilesByOwner(
    $owner: String!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFileRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFilesByOwner(
      owner: $owner
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        id
        owner
        updatedAt
        fileDateModified
        fileName
        fileSizeBytes
        status
        s3Key
        storageIdKey
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
    }
  }
`;

export const getCandidatePromoPath = /* GraphQL */ `
  query GetCandidatePromoPath($id: ID!) {
    getPromoPath(id: $id) {
      id
      createdAt
      updatedAt
      allowAttachments
      candidate
      categoryIds
      status
      manager
      name
      role
      startLevel
      suggestedLPs
      targetDate
      targetLevel
      templateId
      totalCompleted
      totalRequired
      categories {
        items {
          createdAt
          id
          updatedAt
          description
          isDefault
          name
          role
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      template {
        createdAt
        id
        updatedAt
        allowAttachments
        categoryIds
        name
        status
        role
        startLevel
        suggestedLPs
        targetLevel
        totalRequired
        categories {
          items {
            createdAt
            id
            updatedAt
            description
            isDefault
            name
            role
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
        }
        _version
        _deleted
        _lastChangedAt
      }
      workSummaries {
        items {
          id
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const listCandidatePathsByCandidate = /* GraphQL */ `
  query ListPromoPathsByCandidate(
    $candidate: String!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPromoPathFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPromoPathsByCandidate(
      candidate: $candidate
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        allowAttachments
        candidate
        status
        manager
        name
        role
        startLevel
        suggestedLPs
        targetDate
        targetLevel
        templateId
        totalCompleted
        totalRequired
        categories {
          items {
            createdAt
            id
            updatedAt
            description
            isDefault
            name
            role
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
        }
        template {
          createdAt
          id
          updatedAt
          allowAttachments
          categoryIds
          status
          name
          role
          startLevel
          suggestedLPs
          targetLevel
          totalRequired
          categories {
            items {
              createdAt
              id
              updatedAt
              description
              isDefault
              name
              role
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
          }
          _version
          _deleted
          _lastChangedAt
        }
        workSummaries {
          items {
            id
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
    }
  }
`;

export const listCandidatePathsByManager = /* GraphQL */ `
  query ListCandidatePathsByManager(
    $manager: String!
    $sortDirection: ModelSortDirection
    $filter: ModelPromoPathFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPromoPathsByManagerAndType(
      manager: $manager
      pathTypeStatus: { eq: { status: ASSIGNED, pathType: CANDIDATE } }
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        allowAttachments
        candidate
        status
        manager
        name
        role
        startLevel
        suggestedLPs
        targetDate
        targetLevel
        templateId
        totalCompleted
        totalRequired
        categories {
          items {
            createdAt
            id
            updatedAt
            description
            isDefault
            name
            role
            status
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
        }
        template {
          createdAt
          id
          updatedAt
          allowAttachments
          status
          name
          role
          startLevel
          suggestedLPs
          targetLevel
          totalRequired
          categories {
            items {
              createdAt
              id
              updatedAt
              description
              isDefault
              name
              role
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
          }
          _version
          _deleted
          _lastChangedAt
        }
        workSummaries(
          filter: {
            and: [
              { status: { ne: DRAFT } },
              { status: { ne: DELETED } }
            ]
          }
        ) {
          items {
            createdAt
            id
            coOwners
            updatedAt
            alias
            candidatePathId
            leadershipPrinciples
            status
            statusReason
            submittedAt
            title
            type
            categories {
              items {
                id
                name
                status
              }
            }
            comments(
              typeStatus: { eq: { type: CANDIDATE, status: ACTIVE } }
              limit: 1000,
            ) {
              items {
                id
              }
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
    }
  }
`;

export const listCandidatePathsByTemplate = /* GraphQL */ `
  query ListCandidatePathsByTemplate(
    $templateId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPromoPathFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPromoPathsByTemplateAndType(
      templateId: $templateId
      pathTypeStatus: { beginsWith: { pathType: CANDIDATE } }
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        allowAttachments
        candidate
        status
        manager
        name
        role
        startLevel
        suggestedLPs
        targetDate
        targetLevel
        templateId
        totalCompleted
        totalRequired
        categories {
          items {
            createdAt
            id
            updatedAt
            description
            isDefault
            name
            role
            status
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
        }
        workSummaries {
          items {
            createdAt
            id
            coOwners
            updatedAt
            alias
            candidatePathId
            leadershipPrinciples
            status
            statusReason
            submittedAt
            title
            type
            categories {
              items {
                id
                name
              }
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
    }
  }
`;

export const getPromoPath = /* GraphQL */ `
  query GetPromoPath($id: ID!) {
    getPromoPath(id: $id) {
      createdAt
      id
      updatedAt
      allowAttachments
      status
      name
      role
      startLevel
      suggestedLPs
      targetLevel
      totalRequired
      descendants {
        items {
          id
          candidate
          name
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      categories {
        items {
          createdAt
          id
          updatedAt
          description
          isDefault
          name
          role
          status
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const listPromoPathsByOwner = /* GraphQL */ `
  query ListPromoPathsByOwner(
    $manager: String!
    $sortDirection: ModelSortDirection
    $filter: ModelPromoPathFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPromoPathsByManagerAndType(
      manager: $manager
      pathTypeStatus: { eq: { status: ACTIVE, pathType: MANAGER } }
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        id
        updatedAt
        allowAttachments
        categoryIds
        status
        name
        role
        startLevel
        suggestedLPs
        targetLevel
        descendants {
          items {
            id
            candidate
            name
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
        }
        categories {
          items {
            createdAt
            id
            updatedAt
            description
            isDefault
            name
            role
            status
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
    }
  }
`;

export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      createdAt
      id
      updatedAt
      description
      isDefault
      name
      role
      status
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const listCategoriesByRole = /* GraphQL */ `
  query ListCategoriesByRole(
    $role: String!
    $isDefault: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategoriesByRole(
      role: $role
      isDefault: $isDefault
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        id
        updatedAt
        description
        isDefault
        name
        role
        status
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
    }
  }
`;

export const getUserIdentityId = /* GraphQL */ `
  query GetUserIdentityId($alias: String!) {
    getUserProfile(alias: $alias) {
      identityId
    }
  }
`;

export const getUserProfileWithIdentity = /* GraphQL */ `
  query GetUserProfileWithIdentity($alias: String!) {
    getUserProfile(alias: $alias) {
      alias
      createdAt
      updatedAt
      email
      hasLoggedIn
      hireDate
      identityId
      isManager
      jobLevel
      jobTitle
      manager
      managerProfile {
        alias
        email
        name
      }
      name
      orgName
      personId
      preferences {
        landingPage
        visualMode
        density
      }
      status
      sharedFiles {
        items {
          id
          fileRecord {
            createdAt
            id
            owner
            updatedAt
            fileDateModified
            fileName
            fileSizeBytes
            status
            s3Key
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const getUserProfile = /* GraphQL */ `
  query GetUserProfile($alias: String!) {
    getUserProfile(alias: $alias) {
      alias
      createdAt
      updatedAt
      email
      hasLoggedIn
      hireDate
      isManager
      jobLevel
      jobTitle
      manager
      managerProfile {
        alias
        email
        name
      }
      name
      orgName
      personId
      preferences {
        landingPage
        visualMode
        density
      }
      status
      sharedFiles {
        items {
          id
          fileRecord {
            createdAt
            id
            owner
            updatedAt
            fileDateModified
            fileName
            fileSizeBytes
            status
            s3Key
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const getWorkSummaryFiles = /* GraphQL */ `
  query GetWorkSummaryFiles($id: ID!) {
    getWorkSummaryFiles(id: $id) {
      id
      workSummaryID
      fileRecordID
      workSummary {
        id
        title
        documents {
          items {
            id
            fileRecordID
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
        }
        _version
        _deleted
        _lastChangedAt
      }
      fileRecord {
        id
        storageIdKey
        workSummaries {
          items {
            id
            workSummaryID
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
        }
        users {
          items {
            id
            userProfileAlias
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
        }
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;

export const getSharedUserFiles = /* GraphQL */ `
  query GetSharedUserFiles($id: ID!) {
    getSharedUserFiles(id: $id) {
      id
      fileRecordID
      userProfileAlias
      fileRecord {
        createdAt
        id
        owner
        updatedAt
        fileDateModified
        fileName
        fileSizeBytes
        status
        s3Key
        workSummaries {
          items {
            id
            workSummaryID
            fileRecordID
            workSummary {
              createdAt
              id
              coOwners
              updatedAt
              alias
              candidatePathId
              categoryIds
              content
              leadershipPrinciples
              status
              statusReason
              submittedAt
              title
              type
              categories {
                items {
                  createdAt
                  id
                  updatedAt
                  description
                  isDefault
                  name
                  role
                  _version
                  _deleted
                  _lastChangedAt
                  owner
                }
              }
              documents {
                items {
                  id
                  workSummaryID
                  fileRecordID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  owner
                }
                nextToken
              }
              _version
              _deleted
              _lastChangedAt
            }
            fileRecord {
              createdAt
              id
              owner
              updatedAt
              fileDateModified
              fileName
              fileSizeBytes
              status
              s3Key
              workSummaries {
                items {
                  id
                  workSummaryID
                  fileRecordID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  owner
                }
                nextToken
              }
              users {
                items {
                  id
                  fileRecordID
                  userProfileAlias
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  owner
                }
                nextToken
              }
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
          }
          nextToken
        }
        users {
          items {
            id
            fileRecordID
            userProfileAlias
            fileRecord {
              createdAt
              id
              owner
              updatedAt
              fileDateModified
              fileName
              fileSizeBytes
              status
              s3Key
              workSummaries {
                items {
                  id
                  workSummaryID
                  fileRecordID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  owner
                }
                nextToken
              }
              users {
                items {
                  id
                  fileRecordID
                  userProfileAlias
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  owner
                }
                nextToken
              }
              _version
              _deleted
              _lastChangedAt
            }
            userProfile {
              alias
              createdAt
              owner
              updatedAt
              email
              hasLoggedIn
              personId
              preferences {
                landingPage
                visualMode
                density
              }
              sharedFiles {
                items {
                  id
                  fileRecordID
                  userProfileAlias
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  owner
                }
                nextToken
              }
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
          }
          nextToken
        }
        _version
        _deleted
        _lastChangedAt
      }
      userProfile {
        alias
        createdAt
        owner
        updatedAt
        email
        hasLoggedIn
        personId
        preferences {
          landingPage
          visualMode
          density
        }
        sharedFiles {
          items {
            id
            fileRecordID
            userProfileAlias
            fileRecord {
              createdAt
              id
              owner
              updatedAt
              fileDateModified
              fileName
              fileSizeBytes
              status
              s3Key
              workSummaries {
                items {
                  id
                  workSummaryID
                  fileRecordID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  owner
                }
                nextToken
              }
              users {
                items {
                  id
                  fileRecordID
                  userProfileAlias
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  owner
                }
                nextToken
              }
              _version
              _deleted
              _lastChangedAt
            }
            userProfile {
              alias
              createdAt
              owner
              updatedAt
              email
              hasLoggedIn
              personId
              preferences {
                landingPage
                visualMode
                density
              }
              sharedFiles {
                items {
                  id
                  fileRecordID
                  userProfileAlias
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  owner
                }
                nextToken
              }
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
          }
          nextToken
        }
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;

export const getPeerReview = /* GraphQL */ `
  query GetPeerReview($id: ID!) {
    getPeerReview(id: $id) {
      createdAt
      id
      coOwners
      updatedAt
      requiredBy
      candidatePathId
      reviewerAlias
      reviewer {
        name
      }
      reviewStatus
      reviewType
      content
      reviewedContentVersion
      candidatePath {
        id
        candidate
        manager
        name
        targetDate
        targetLevel
        template {
          id
          name
          targetLevel
        }
      }
      workSummary {
        id
        alias
        content
        contentVersion
        status
        title
        type
        categories {
          items {
            id
            name
            description
          }
        }
        documents {
          items {
            id
            fileRecord {
              createdAt
              id
              owner
              updatedAt
              fileDateModified
              fileName
              fileSizeBytes
              status
              s3Key
              storageIdKey
              _deleted
            }
            _deleted
          }
        }
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const listPeerReviewsByWorkSummary = /* GraphQL */ `
  query ListPeerReviewsByWorkSummary(
    $workSummaryId: ID!
    $reviewType: ModelPeerReviewTypeInput!
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    getWorkSummary(id: $workSummaryId) {
      contentVersion
      peerReviews(
        filter: {
          and: [
            { reviewStatus: { ne: DELETED } },
            { reviewType: $reviewType }
          ]
        }
        limit: $limit
        nextToken: $nextToken
        sortDirection: $sortDirection
      ) {
        items {
          createdAt
          id
          coOwners
          updatedAt
          reviewerAlias
          reviewer {
            name
          }
          reviewStatus
          reviewType
          content
          reviewedContentVersion
          requiredBy
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
    }
  }
`;

export const listPeerReviewsByCandidatePath = /* GraphQL */ `
  query ListPeerReviewsByCandidatePath(
    $candidatePathId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPeerReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPeerReviewsByCandidatePath(
      candidatePathId: $candidatePathId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        id
        coOwners
        updatedAt
        content
        reviewerAlias
        reviewStatus
        reviewType
        reviewedContentVersion
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
    }
  }
`;

export const listPeerReviewsByReviewer = /* GraphQL */ `
  query ListPeerReviewsByReviewer(
    $reviewerAlias: String!
    $reviewStatus: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPeerReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPeerReviewsByReviewer(
      reviewerAlias: $reviewerAlias
      reviewStatus: $reviewStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        id
        coOwners
        updatedAt
        requiredBy
        content
        reviewerAlias
        reviewer {
          name
        }
        reviewStatus
        reviewType
        reviewedContentVersion
        candidatePath {
          id
          candidate
          name
          manager
          targetLevel
          template {
            id
            name
            targetLevel
          }
        }
        workSummary {
          id
          title
          alias
          content
          type
          categories {
            items {
              id
              name
            }
          }
          comments(
            typeStatus: { eq: { type: PEER_REVIEW, status: ACTIVE } }
            limit: 1000
          ) {
            items {
              id
            }
          }
          contentVersion
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
    }
  }
`;

export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      createdAt
      id
      updatedAt
      type
      content
      workSummaryId
      status
      author {
        alias
        name
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const listCommentsByWorkSummaryAndType = /* GraphQL */ `
  query ListCommentsByWorkSummaryAndType(
    $workSummaryId: ID!
    $type: CommentType!
    $status: CommentStatus!
    $sortDirection: ModelSortDirection
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommentsByWorkSummary(
      workSummaryId: $workSummaryId
      typeStatus: { eq: { type: $type status: $status } }
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        id
        updatedAt
        type
        content
        status
        workSummaryId
        author {
          alias
          name
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
    }
  }
`;

export const listAuditTrailsByTypeAndId = /* GraphQL */ `
  query ListAuditTrailsByTypeAndId(
    $recordId: ID!
    $recordType: AuditTrailRecordType!
    $limit: Int
    $nextToken: String
  ) {
    listAuditTrailsByTypeAndId(
      recordType: $recordType
      recordId: { eq: $recordId }
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        modifiedBy
        recordId
        recordType
        humanReadableLog
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
    }
  }
`;


export const getDocumentReviewSessionRecord = /* GraphQL */ `query GetDocumentReviewSessionRecord($id: ID!) {
  getDocumentReviewSessionRecord(id: $id) {
    id
    createdAt
    updatedAt
    ownerAlias
    candidateAlias
    workdocsLink
    chimeLink
    sessionStart
    sessionState
    documentReviewers {
      items {
        id
        documentReviewSessionRecordID
        calibratedDocumentReviewerRecordID
        documentReviewSessionRecord {
          id
          createdAt
          updatedAt
          ownerAlias
          candidateAlias
          workdocsLink
          chimeLink
          sessionStart
          sessionState
          documentReviewers {
            items {
              id
              documentReviewSessionRecordID
              calibratedDocumentReviewerRecordID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          lastModifiedBy
          owner
          __typename
        }
        calibratedDocumentReviewerRecord {
          id
          createdAt
          updatedAt
          alias
          completedSessions
          documentReviews {
            items {
              id
              documentReviewSessionRecordID
              calibratedDocumentReviewerRecordID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          lastModifiedBy
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    lastModifiedBy
    owner
    __typename
  }
}
`;
export const listDocumentReviewSessionsByOwner = /* GraphQL */ `query ListDocumentReviewSessionsByOwner(
  $ownerAlias: String!
  $sessionState: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelDocumentReviewSessionRecordFilterInput
  $limit: Int
  $nextToken: String
) {
  listDocumentReviewSessionsByOwner(
    ownerAlias: $ownerAlias
    sessionState: $sessionState
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      createdAt
      updatedAt
      ownerAlias
      candidateAlias
      workdocsLink
      chimeLink
      sessionStart
      sessionState
      documentReviewers {
        items {
          id
          documentReviewSessionRecordID
          calibratedDocumentReviewerRecordID
          documentReviewSessionRecord {
            id
            createdAt
            updatedAt
            ownerAlias
            candidateAlias
            workdocsLink
            chimeLink
            sessionStart
            sessionState
            documentReviewers {
              nextToken
              startedAt
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            lastModifiedBy
            owner
            __typename
          }
          calibratedDocumentReviewerRecord {
            id
            createdAt
            updatedAt
            alias
            completedSessions
            documentReviews {
              nextToken
              startedAt
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            lastModifiedBy
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      lastModifiedBy
      owner
      __typename
    }
    nextToken
    __typename
  }
}
`;
export const getCalibratedDocumentReviewerRecord = /* GraphQL */ `query GetCalibratedDocumentReviewerRecord($id: ID!) {
  getCalibratedDocumentReviewerRecord(id: $id) {
    id
    createdAt
    updatedAt
    alias
    completedSessions
    documentReviews {
      items {
        id
        documentReviewSessionRecordID
        calibratedDocumentReviewerRecordID
        documentReviewSessionRecord {
          id
          createdAt
          updatedAt
          ownerAlias
          candidateAlias
          workdocsLink
          chimeLink
          sessionStart
          sessionState
          documentReviewers {
            items {
              id
              documentReviewSessionRecordID
              calibratedDocumentReviewerRecordID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          lastModifiedBy
          owner
          __typename
        }
        calibratedDocumentReviewerRecord {
          id
          createdAt
          updatedAt
          alias
          completedSessions
          documentReviews {
            items {
              id
              documentReviewSessionRecordID
              calibratedDocumentReviewerRecordID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          lastModifiedBy
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    lastModifiedBy
    owner
    __typename
  }
}
`;

export const listCalibratedDocumentReviewerRecords = /* GraphQL */ `query ListCalibratedDocumentReviewerRecords(
  $id: ID
  $filter: ModelCalibratedDocumentReviewerRecordFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCalibratedDocumentReviewerRecords(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      createdAt
      updatedAt
      alias
      completedSessions
      documentReviews {
        items {
          id
          documentReviewSessionRecordID
          calibratedDocumentReviewerRecordID
          documentReviewSessionRecord {
            id
            createdAt
            updatedAt
            ownerAlias
            candidateAlias
            workdocsLink
            chimeLink
            sessionStart
            sessionState
            documentReviewers {
              nextToken
              startedAt
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            lastModifiedBy
            owner
            __typename
          }
          calibratedDocumentReviewerRecord {
            id
            createdAt
            updatedAt
            alias
            completedSessions
            documentReviews {
              nextToken
              startedAt
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            lastModifiedBy
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      lastModifiedBy
      owner
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
`;

export const getPanelReviewRecord = /* GraphQL */ `query GetPanelReviewRecord($id: ID!) {
  getPanelReviewRecord(id: $id) {
    id
    createdAt
    updatedAt
    candidateAlias
    workdocsLink
    chimeLink
    sessionStart
    sessionState
    _version
    _deleted
    _lastChangedAt
    lastModifiedBy
    owner
    __typename
  }
}
`;
export const getCalibratedPanelReviewerRecord = /* GraphQL */ `query GetCalibratedPanelReviewerRecord($candidateAlias: String!) {
  getCalibratedPanelReviewerRecord(candidateAlias: $candidateAlias) {
    createdAt
    updatedAt
    candidateAlias
    completedSessions
    _version
    _deleted
    _lastChangedAt
    lastModifiedBy
    owner
    __typename
  }
}
`;
export const getDocumentReviewSessionToReviewerMapping = /* GraphQL */ `query GetDocumentReviewSessionToReviewerMapping($id: ID!) {
  getDocumentReviewSessionToReviewerMapping(id: $id) {
    id
    documentReviewSessionRecordID
    calibratedDocumentReviewerRecordID
    documentReviewSessionRecord {
      id
      createdAt
      updatedAt
      ownerAlias
      candidateAlias
      workdocsLink
      chimeLink
      sessionStart
      sessionState
      documentReviewers {
        items {
          id
          documentReviewSessionRecordID
          calibratedDocumentReviewerRecordID
          documentReviewSessionRecord {
            id
            createdAt
            updatedAt
            ownerAlias
            candidateAlias
            workdocsLink
            chimeLink
            sessionStart
            sessionState
            documentReviewers {
              nextToken
              startedAt
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            lastModifiedBy
            owner
            __typename
          }
          calibratedDocumentReviewerRecord {
            id
            createdAt
            updatedAt
            alias
            completedSessions
            documentReviews {
              nextToken
              startedAt
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            lastModifiedBy
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      lastModifiedBy
      owner
      __typename
    }
    calibratedDocumentReviewerRecord {
      id
      createdAt
      updatedAt
      alias
      completedSessions
      documentReviews {
        items {
          id
          documentReviewSessionRecordID
          calibratedDocumentReviewerRecordID
          documentReviewSessionRecord {
            id
            createdAt
            updatedAt
            ownerAlias
            candidateAlias
            workdocsLink
            chimeLink
            sessionStart
            sessionState
            documentReviewers {
              nextToken
              startedAt
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            lastModifiedBy
            owner
            __typename
          }
          calibratedDocumentReviewerRecord {
            id
            createdAt
            updatedAt
            alias
            completedSessions
            documentReviews {
              nextToken
              startedAt
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            lastModifiedBy
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      lastModifiedBy
      owner
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}
`;
export const listDocumentReviewSessionToReviewerMappings = /* GraphQL */ `query ListDocumentReviewSessionToReviewerMappings(
  $filter: ModelDocumentReviewSessionToReviewerMappingFilterInput
  $limit: Int
  $nextToken: String
) {
  listDocumentReviewSessionToReviewerMappings(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      documentReviewSessionRecordID
      calibratedDocumentReviewerRecordID
      documentReviewSessionRecord {
        id
        createdAt
        updatedAt
        ownerAlias
        candidateAlias
        workdocsLink
        chimeLink
        sessionStart
        sessionState
        documentReviewers {
          items {
            id
            documentReviewSessionRecordID
            calibratedDocumentReviewerRecordID
            documentReviewSessionRecord {
              id
              createdAt
              updatedAt
              ownerAlias
              candidateAlias
              workdocsLink
              chimeLink
              sessionStart
              sessionState
              _version
              _deleted
              _lastChangedAt
              lastModifiedBy
              owner
              __typename
            }
            calibratedDocumentReviewerRecord {
              id
              createdAt
              updatedAt
              alias
              completedSessions
              _version
              _deleted
              _lastChangedAt
              lastModifiedBy
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        lastModifiedBy
        owner
        __typename
      }
      calibratedDocumentReviewerRecord {
        id
        createdAt
        updatedAt
        alias
        completedSessions
        documentReviews {
          items {
            id
            documentReviewSessionRecordID
            calibratedDocumentReviewerRecordID
            documentReviewSessionRecord {
              id
              createdAt
              updatedAt
              ownerAlias
              candidateAlias
              workdocsLink
              chimeLink
              sessionStart
              sessionState
              _version
              _deleted
              _lastChangedAt
              lastModifiedBy
              owner
              __typename
            }
            calibratedDocumentReviewerRecord {
              id
              createdAt
              updatedAt
              alias
              completedSessions
              _version
              _deleted
              _lastChangedAt
              lastModifiedBy
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        lastModifiedBy
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
`;
export const syncDocumentReviewSessionToReviewerMappings = /* GraphQL */ `query SyncDocumentReviewSessionToReviewerMappings(
  $filter: ModelDocumentReviewSessionToReviewerMappingFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncDocumentReviewSessionToReviewerMappings(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      documentReviewSessionRecordID
      calibratedDocumentReviewerRecordID
      documentReviewSessionRecord {
        id
        createdAt
        updatedAt
        ownerAlias
        candidateAlias
        workdocsLink
        chimeLink
        sessionStart
        sessionState
        documentReviewers {
          items {
            id
            documentReviewSessionRecordID
            calibratedDocumentReviewerRecordID
            documentReviewSessionRecord {
              id
              createdAt
              updatedAt
              ownerAlias
              candidateAlias
              workdocsLink
              chimeLink
              sessionStart
              sessionState
              _version
              _deleted
              _lastChangedAt
              lastModifiedBy
              owner
              __typename
            }
            calibratedDocumentReviewerRecord {
              id
              createdAt
              updatedAt
              alias
              completedSessions
              _version
              _deleted
              _lastChangedAt
              lastModifiedBy
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        lastModifiedBy
        owner
        __typename
      }
      calibratedDocumentReviewerRecord {
        id
        createdAt
        updatedAt
        alias
        completedSessions
        documentReviews {
          items {
            id
            documentReviewSessionRecordID
            calibratedDocumentReviewerRecordID
            documentReviewSessionRecord {
              id
              createdAt
              updatedAt
              ownerAlias
              candidateAlias
              workdocsLink
              chimeLink
              sessionStart
              sessionState
              _version
              _deleted
              _lastChangedAt
              lastModifiedBy
              owner
              __typename
            }
            calibratedDocumentReviewerRecord {
              id
              createdAt
              updatedAt
              alias
              completedSessions
              _version
              _deleted
              _lastChangedAt
              lastModifiedBy
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        lastModifiedBy
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
`;

export const getCalibratedDocumentReviewerRecordByAlias = /* GraphQL */ `query GetCalibratedDocumentReviewerRecordByAlias(
  $alias: String!
  $sortDirection: ModelSortDirection
  $filter: ModelCalibratedDocumentReviewerRecordFilterInput
  $limit: Int
  $nextToken: String
) {
  getCalibratedDocumentReviewerRecordByAlias(
    alias: $alias
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      createdAt
      updatedAt
      alias
      completedSessions
      documentReviews {
        items {
          id
          documentReviewSessionRecordID
          calibratedDocumentReviewerRecordID
          documentReviewSessionRecord {
            id
            createdAt
            updatedAt
            ownerAlias
            candidateAlias
            workdocsLink
            chimeLink
            sessionStart
            sessionState
            _version
            _deleted
            _lastChangedAt
            lastModifiedBy
            owner
            __typename
          }
          calibratedDocumentReviewerRecord {
            id
            createdAt
            updatedAt
            alias
            completedSessions
            _version
            _deleted
            _lastChangedAt
            lastModifiedBy
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      lastModifiedBy
      owner
      __typename
    }
    nextToken
    __typename
  }
}
`;
