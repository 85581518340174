import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import Form from '@amzn/awsui-components-react/polaris/form';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Multiselect from '@amzn/awsui-components-react/polaris/multiselect';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import Input from '@amzn/awsui-components-react/polaris/input';
import { DatePicker, Select, TimeInput } from '@amzn/awsui-components-react/polaris';
import { OptionDefinition } from 'node_modules/@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import {
  useCalibratedDocumentReviewerRecords,
  useDocumentReviewers,
  useDocumentReviewSession,
  useDocumentReviewSessionActions,
} from '@/api/document-review';
import { useAppContext } from '@/contexts';
import { CalibratedReviewerItem, DocumentReviewSessionResource, Nullable } from '@/models';
import { DocumentReviewSessionState } from '@/api/API';
import { DocumentReviewSessionsPage } from '@/common/pages';
import useNavigator from '@/common/hooks/use-navigator';
import { checkAlias, checkChimeLink, checkDocumentReviewers, checkWorkdocsLink } from './form-validator';

interface FormParams {
  documentReviewSessionId?: string;
}

const timezoneOptions = [
  { label: 'UTC', value: '00:00' },
  { label: 'UTC-3 (ART)', value: '-03:00' },
  { label: 'UTC-4 (AMT)', value: '-04:00' },
  { label: 'UTC-5 (EST)', value: '-05:00' },
  { label: 'UTC-6 (CST)', value: '-06:00' },
  { label: 'UTC-7 (MST)', value: '-07:00' },
  { label: 'UTC-8 (PST)', value: '-08:00' },
  { label: 'UTC-9 (AKST)', value: '-09:00' },
  { label: 'UTC-10 (HST)', value: '-10:00' },
  { label: 'UTC-11 (SST)', value: '-11:00' },
  { label: 'UTC+1 (CET)', value: '+01:00' },
  { label: 'UTC+2 (EET)', value: '+02:00' },
  { label: 'UTC+3 (MSK)', value: '+03:00' },
  { label: 'UTC+4 (GST)', value: '+04:00' },
  { label: 'UTC+5 (PKT)', value: '+05:00' },
  { label: 'UTC+6 (BST)', value: '+06:00' },
  { label: 'UTC+7 (IST)', value: '+07:00' },
  { label: 'UTC+8 (WIB)', value: '+08:00' },
  { label: 'UTC+9 (JST)', value: '+09:00' },
  { label: 'UTC+10 (AEST)', value: '+10:00' },
  { label: 'UTC+11 (NZST)', value: '+11:00' },
  { label: 'UTC+12 (NZDT)', value: '+12:00' },
];

const DocumentReviewSessionCreateForm = ({ documentReviewSessionId }: FormParams): JSX.Element => {
  const { currentUser, spoofUser } = useAppContext();
  const [id, setId] = useState(documentReviewSessionId);
  const [candidateAlias, setCandidateAlias] = useState('');
  const [reviewers, setReviewers] = useState<string[]>([]);
  const [originalReviewers, setOriginalReviewers] = useState<CalibratedReviewerItem[]>([]);
  const [workdocsLink, setWorkdocsLink] = useState('');
  const [startDate, setStartDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [startTZ, setStartTZ] = useState<OptionDefinition | null>(null);
  const [chimeLink, setChimeLink] = useState('');
  const { documentReviewSession, isDocumentReviewSessionLoading } = useDocumentReviewSession(id);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { goToPage } = useNavigator();
  const { actions: documentReviewActions } = useDocumentReviewSessionActions();
  const { attachReviewerByAlias, getReviewerByAlias } = useDocumentReviewers();
  const { calibratedDocumentReviewers, isCalibratedDocumentReviewersLoading } = useCalibratedDocumentReviewerRecords();
  const reviewerOptions = calibratedDocumentReviewers.map((reviewer) => ({
    label: reviewer.alias,
    value: reviewer.alias,
  }));

  const isParamsValid = () =>
    !(
      checkAlias(candidateAlias) ||
      checkDocumentReviewers(reviewers) ||
      checkWorkdocsLink(workdocsLink) ||
      checkChimeLink(chimeLink)
    );

  useEffect(() => {
    if (documentReviewSession) {
      setCandidateAlias(documentReviewSession.candidateAlias);
      setWorkdocsLink(documentReviewSession.workdocsLink);
      setChimeLink(documentReviewSession.chimeLink);
      if (documentReviewSession?.documentReviewers.length) {
        setReviewers(documentReviewSession.documentReviewers.map((reviewer) => reviewer.id));
        setOriginalReviewers(documentReviewSession.documentReviewers);
      }
    }
  }, [documentReviewSession]);

  // const validateForm = () => {
  //   const errors: any = {};
  //   if (!createSessionData.candidateAlias) errors.candidate = 'Candidate is required';
  //   if (createSessionData.reviewers.length < 2) errors.reviewers = 'At least 2 reviewers are required';
  //   if (createSessionData.reviewers.length > 4) errors.reviewers = 'Maximum 4 reviewers are allowed';
  //   if (!createSessionData.workdocsLink) errors.workdocsLink = 'Workdocs link is required';
  //   if (!createSessionData.date) errors.date = 'Date is required';
  //   if (!createSessionData.time) errors.time = 'Time is required';
  //   if (!createSessionData.timezone) errors.timezone = 'Timezone is required';
  //   return errors;
  // };

  const saveDocumentReviewSession = async () => {
    const input = {
      ownerAlias: currentUser?.alias ?? '',
      candidateAlias,
      workdocsLink,
      chimeLink,
      sessionStart: `${startDate}T${startTime}${startTZ?.value}`,
    };

    let result: Nullable<DocumentReviewSessionResource>;

    if (documentReviewSession?.id) {
      result = await documentReviewActions.update({ ...input });
    } else {
      result = await documentReviewActions.create({ ...input, sessionState: DocumentReviewSessionState.SCHEDULED });
    }

    if (result?.id) {
      const originalReviewerAliases = originalReviewers.map((item) => item.alias);
      const reviewersToAdd = reviewers.filter((reviewer) => !originalReviewerAliases.includes(reviewer));
      const reviewersToRemove = originalReviewerAliases.filter(
        (originalReviewerId) => !reviewers.includes(originalReviewerId)
      );

      const reviewSessionId = result.id;
      const reviewerIds = reviewersToAdd.map((reviewer) => {
        const reviewerRecord = calibratedDocumentReviewers.find((item) => item.alias === reviewer);
        return reviewerRecord?.id;
      });

      if (reviewersToAdd.length) {
        await Promise.all(
          reviewersToAdd.map(async (reviewer) =>
            attachReviewerByAlias({
              documentReviewSessionRecordID: reviewSessionId,
              alias: reviewer,
            })
          )
        );
      }
    }
  };

  const onCreate = () => {
    if (!isParamsValid()) {
      return;
    }

    setIsSubmitting(true);
    saveDocumentReviewSession().then(() => goToPage(DocumentReviewSessionsPage));
  };

  return (
    <Form
      actions={
        <SpaceBetween direction="horizontal" size="l">
          <Button onClick={() => onCreate()} variant="primary" loading={isSubmitting}>
            {isSubmitting ? 'Creating...' : 'Create review session'}
          </Button>
          <Button onClick={() => navigate(-1)} variant="link">
            Cancel
          </Button>
        </SpaceBetween>
      }
    >
      <SpaceBetween direction="vertical" size="l">
        <FormField label="Candidate*">
          <Input
            placeholder="Enter candidate alias"
            onChange={({ detail }) => setCandidateAlias(detail.value)}
            value={candidateAlias}
          />
        </FormField>
        <FormField label="Calibrated Doc Reviewers*" description="Select 2-4 reviewers">
          <Multiselect
            placeholder="Select Reviewers*"
            selectedOptions={reviewers.map((reviewer) => {
              return {
                label: reviewer,
                value: reviewer,
              };
            })}
            onChange={({ detail }) => setReviewers(detail.selectedOptions.map((reviewer) => reviewer.value!))}
            options={reviewerOptions}
          />
        </FormField>
        <FormField label="Workdocs link to doc artifact">
          <Input
            placeholder="Input Workdocs link*"
            value={workdocsLink}
            onChange={({ detail }) => setWorkdocsLink(detail.value)}
          />
        </FormField>
        <FormField label="Date and time*">
          <SpaceBetween direction="horizontal" size="xs">
            <DatePicker
              placeholder="Input Date"
              value={startDate}
              onChange={({ detail }) => setStartDate(detail.value)}
            />
            <TimeInput
              placeholder="Input Time"
              value={startTime}
              onChange={({ detail }) => setStartTime(detail.value)}
            />
            <Select
              placeholder="Select Time Zone"
              selectedOption={startTZ}
              onChange={({ detail }) => setStartTZ(detail.selectedOption!)}
              options={timezoneOptions}
            />
          </SpaceBetween>
        </FormField>
        <FormField label="Chime link (optional)">
          <Input
            placeholder="Input Chime link"
            value={chimeLink}
            onChange={({ detail }) => setChimeLink(detail.value)}
          />
        </FormField>
      </SpaceBetween>
    </Form>
  );
};

export default DocumentReviewSessionCreateForm;
