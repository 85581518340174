import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import Form from '@amzn/awsui-components-react/polaris/form';
import { useNavigate } from 'react-router-dom';
import { DocumentReviewSessionResource, Nullable } from '@/models';
import ViewDocumentReviewSession from './ViewDocumentReviewSession';

interface FormParams {
  documentReviewSession?: Nullable<DocumentReviewSessionResource>;
}

const DocumentReviewSessionViewForm = ({ documentReviewSession }: FormParams): JSX.Element => {
  // const { currentUser, spoofUser } = useAppContext();
  // const [content, setContent] = useState('');
  // const [contentError, setContentError] = useState('');
  const navigate = useNavigate();

  return (
    <Form
      actions={
        <SpaceBetween direction="horizontal" size="l">
          <Button onClick={() => navigate(-1)} variant="link">
            Back
          </Button>
        </SpaceBetween>
      }
    >
      <SpaceBetween direction="vertical" size="l">
        <ViewDocumentReviewSession documentReviewSession={documentReviewSession} />
      </SpaceBetween>
    </Form>
  );
};

export default DocumentReviewSessionViewForm;
