import { Box, SpaceBetween, Button, Select, Form, StatusIndicator } from '@amzn/awsui-components-react';
import { DocumentReviewSessionState } from '@/api/API';

interface SessionStateProps {
  isCreator: boolean;
  sessionState: DocumentReviewSessionState;
  onStateChange: (newState: DocumentReviewSessionState) => void;
  onSaveStateChange: (newState: DocumentReviewSessionState) => void;
}

const SessionState: React.FC<SessionStateProps> = ({ isCreator, sessionState, onStateChange, onSaveStateChange }) => (
  <Box>
    {isCreator ? (
      <form onSubmit={(e) => e.preventDefault()}>
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="primary" onClick={() => onSaveStateChange(sessionState)}>
                Save
              </Button>
            </SpaceBetween>
          }
        >
          <Select
            selectedOption={{ label: sessionState }}
            onChange={({ detail }) => onStateChange(detail.selectedOption.value as DocumentReviewSessionState)}
            options={[
              {
                label: DocumentReviewSessionState.SCHEDULED,
                value: DocumentReviewSessionState.SCHEDULED,
              },
              {
                label: DocumentReviewSessionState.FINAL_VOTE,
                value: DocumentReviewSessionState.FINAL_VOTE,
                disabled: sessionState !== DocumentReviewSessionState.SCHEDULED,
              },
              {
                label: DocumentReviewSessionState.COMPLETE,
                value: DocumentReviewSessionState.COMPLETE,
                disabled: sessionState !== DocumentReviewSessionState.FINAL_VOTE,
              },
            ]}
          />
        </Form>
      </form>
    ) : (
      <StatusIndicator type="info">{sessionState}</StatusIndicator>
    )}
  </Box>
);

export default SessionState;
