import { useCallback, useRef, useState } from 'react';
import { Nullable, PromoUserProfile } from '@/models';
import { DocumentReviewSessionState } from '@/api/API';
import PromoTable from '@/components/common/table/PromoTable';
import { useTableConfig, getPropertyFilters, TABLE_KEY } from './table-config';
import { getActionDefinitions, getHeaderComponent } from './common-components';
import { useDocumentReviewSessions } from '@/api/document-review';
import CancelDocumentReviewSessionModal from './CancelDocumentReviewSessionModal';

interface DocumentReviewTableParams {
  user: Nullable<PromoUserProfile>;
}

const DocumentReviewSessionsTable = ({ user }: DocumentReviewTableParams): JSX.Element => {
  const config = useTableConfig();
  const statusFilters = [DocumentReviewSessionState.SCHEDULED, DocumentReviewSessionState.COMPLETE];
  const [isCancelVisible, setIsCancelVisible] = useState(false);
  const ref = useRef<HTMLElement>(null);
  const { documentReviewSessions, isDocumentReviewSessionsLoading } = useDocumentReviewSessions(
    user?.alias,
    statusFilters
  );

  const onCancel = useCallback(() => setIsCancelVisible(true), []);

  return (
    <>
      <PromoTable
        allItems={documentReviewSessions}
        tableConfig={config}
        headerComponent={getHeaderComponent()}
        isLoading={isDocumentReviewSessionsLoading}
        onEmptyShowCreate
        actionDefs={getActionDefinitions(onCancel)}
        propertyFilters={getPropertyFilters()}
        tableVariant="full-page"
      />
      <CancelDocumentReviewSessionModal
        contextKey={TABLE_KEY}
        isVisible={isCancelVisible}
        onSetVisibility={setIsCancelVisible}
        ref={ref}
      />
    </>
  );
};

export default DocumentReviewSessionsTable;
