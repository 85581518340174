import { SpaceBetween, TextContent, Box } from '@amzn/awsui-components-react';
import { DocumentReviewSessionResource } from '@/models';
import { RESOURCES } from '@/common/constants';
import { RoutableLink } from '@/components/common/links';

const DocInfo: React.FC<{ documentReviewSession: DocumentReviewSessionResource }> = ({ documentReviewSession }) => (
  <SpaceBetween size="m">
    <TextContent>
      <Box fontWeight="bold" variant="span">
        Session ID:
      </Box>{' '}
      <RoutableLink
        href={`${RESOURCES.DOCUMENT_REVIEW_SESSION.hrefToReview}/${documentReviewSession.id?.toString()}`}
        text={documentReviewSession.id?.toString()}
      />
      {/* {documentReviewSession.id?.toString()} */}
    </TextContent>
    <TextContent>
      <Box fontWeight="bold" variant="span">
        Workdocs:
      </Box>{' '}
      <a href={documentReviewSession.workdocsLink} target="_blank" rel="noopener noreferrer">
        Link
      </a>
    </TextContent>
    {documentReviewSession.chimeLink && (
      <TextContent>
        <Box fontWeight="bold" variant="span">
          Chime Link:
        </Box>{' '}
        <a href={documentReviewSession.chimeLink} target="_blank" rel="noopener noreferrer">
          {documentReviewSession.chimeLink}
        </a>
      </TextContent>
    )}
    <TextContent>
      <Box fontWeight="bold" variant="span">
        Session Date/Time:
      </Box>{' '}
      {documentReviewSession.sessionStart?.toString()}
    </TextContent>
    <TextContent>
      <Box fontWeight="bold" variant="span">
        Session Owner:
      </Box>{' '}
      {documentReviewSession.ownerAlias?.toString()}
    </TextContent>
  </SpaceBetween>
);

export default DocInfo;
