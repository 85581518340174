import {
  Table,
  Header,
  SpaceBetween,
  Button,
  FormField,
  Form,
  Select,
  TextContent,
} from '@amzn/awsui-components-react';

import { UserVote, Vote } from '../config';
import { CalibratedReviewerItem, DocumentReviewSessionResource } from '@/models';
import { DocumentReviewSessionState } from '@/api/API';

interface VotingSectionProps {
  isCreator: boolean;
  sessionData: DocumentReviewSessionResource;
  userVote: UserVote;
  onVote: (voteType: keyof UserVote, value: string) => void;
}

const VotingSection: React.FC<VotingSectionProps> = ({ isCreator, sessionData, userVote, onVote }) => {
  if (isCreator) {
    return (
      <Table
        items={sessionData.documentReviewers}
        header={
          <Header
            counter={`(${sessionData.documentReviewers?.length})`}
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  variant="primary"
                  onClick={() => {
                    /* Implement refresh logic */
                  }}
                >
                  Refresh
                </Button>
              </SpaceBetween>
            }
          >
            CalibratedDocumentReviewerRecord
          </Header>
        }
        columnDefinitions={[
          { header: 'Reviewer', cell: (item: CalibratedReviewerItem) => item.alias },
          { header: 'Document Ready', cell: () => 'TODO' },
          { header: 'Candidate Ready', cell: () => 'TODO' },
        ]}
      />
    );
  }

  const isVotingEnabled = sessionData.sessionState === DocumentReviewSessionState.FINAL_VOTE;

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              variant="primary"
              onClick={() => {
                /* Implement save vote logic */
              }}
            >
              Save
            </Button>
          </SpaceBetween>
        }
      >
        <SpaceBetween direction="vertical" size="l">
          <FormField label="Document ready">
            <Select
              selectedOption={userVote.documentReady ? { label: userVote.documentReady } : null}
              onChange={({ detail }) => onVote('documentReady', detail.selectedOption.value as Vote)}
              options={[
                { label: Vote.Yes, value: Vote.Yes },
                { label: Vote.No, value: Vote.No },
              ]}
              disabled={!isVotingEnabled}
              placeholder="Select your vote"
            />
          </FormField>
          <FormField label="Candidate ready">
            <Select
              selectedOption={userVote.candidateReady ? { label: userVote.candidateReady } : null}
              onChange={({ detail }) => onVote('candidateReady', detail.selectedOption.value as Vote)}
              options={[
                { label: Vote.Yes, value: Vote.Yes },
                { label: Vote.No, value: Vote.No },
              ]}
              disabled={!isVotingEnabled}
              placeholder="Select your vote"
            />
          </FormField>
          {!isVotingEnabled && (
            <TextContent>
              <p>Voting is only enabled when the session is in the "Final vote" state.</p>
            </TextContent>
          )}
        </SpaceBetween>
      </Form>
    </form>
  );
};

export default VotingSection;
