import { SpaceBetween, TextContent, Box } from '@amzn/awsui-components-react';
import { useState, useEffect } from 'react';
import { Nullable, PapiProfile } from '@/models';

function timeDiff({ startDate }) {

  const start = new Date(startDate);
  const end = new Date();

  // Calculate the difference in milliseconds
  const diffInMs = end.getTime() - start.getTime();

  // Convert to days, hours, minutes, and seconds
  const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((diffInMs % (1000 * 60)) / 1000);

  return days;
  // return 'Time Difference: {days} days, {timeDiff.hours} hours, {timeDiff.minutes} minutes, {timeDiff.seconds} seconds';
}

const CandidateInfo: React.FC<{ candidateProfile: Nullable<PapiProfile> }> = ({ candidateProfile }) => (
  <SpaceBetween size="xs">
    <TextContent>
      <Box fontWeight="bold" variant="span">
        Name:
      </Box>{' '}
      {candidateProfile?.firstName} {candidateProfile?.lastName}
    </TextContent>
    <TextContent>
      <Box fontWeight="bold" variant="span">
        Role:
      </Box>{' '}
      {candidateProfile?.businessTitle}
    </TextContent>
    <TextContent>
      <Box fontWeight="bold" variant="span">
        Level:
      </Box>{' '}
      {candidateProfile?.jobLevel}
    </TextContent>
    <TextContent>
      <Box fontWeight="bold" variant="span">
        Last hire date:
      </Box>{' '}
      {candidateProfile?.lastHireDate}
    </TextContent>
    <TextContent>
      <Box fontWeight="bold" variant="span">
        Current manager:
      </Box>{' '}
      "TODO"
    </TextContent>
  </SpaceBetween>
);

export default CandidateInfo;
