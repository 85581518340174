import { MAX_FILES_PER_WORKSUMMARY } from '@/common/constants';

export function checkAlias(value: string | undefined): string {
  if (!value || !value.trim().length) {
    return 'Candidate alias cannot be empty.';
  }
  if (value.length > 8 && value.length < 5) {
    return 'Amazon alias must be 8 characters max.';
  }
  return '';
}

export function checkDocumentReviewers(value: string[]): string {
  return value.length > MAX_FILES_PER_WORKSUMMARY ? `Cannot attach more than ${MAX_FILES_PER_WORKSUMMARY} files.` : '';
}

export function checkWorkdocsLink(value: string | undefined): string {
  if (!value || !value.trim().length) {
    return 'Workdocs link cannot be empty';
  }

  // if (!/^[0-9\p{L}\p{M}\s'-.|]*$/u.test(value)) {
  //   return 'Title can only contain letters, numbers, spaces, and ,-. chars.';
  // }

  // if (value.length > MAX_CHAR_TITLE) {
  //   return `Title cannot exceed ${MAX_CHAR_TITLE} characters.`;
  // }
  return '';
}

export function checkChimeLink(value: string | undefined): string {
  if (!value || !value.trim().length) {
    return 'Workdocs link cannot be empty';
  }

  // if (!/^[0-9\p{L}\p{M}\s'-.|]*$/u.test(value)) {
  //   return 'Title can only contain letters, numbers, spaces, and ,-. chars.';
  // }

  // if (value.length > MAX_CHAR_TITLE) {
  //   return `Title cannot exceed ${MAX_CHAR_TITLE} characters.`;
  // }
  return '';
}
