import Container from '@amzn/awsui-components-react/polaris/container';
import Header from '@amzn/awsui-components-react/polaris/header';
import Multiselect from '@amzn/awsui-components-react/polaris/multiselect';
import type { SelectProps } from '@amzn/awsui-components-react/polaris/select';
import { LEADERSHIP_PRINCIPLES, PILLARS } from '@/common/constants';
import { MultiSelectionFieldParams } from '@/models';
import { InfoLink } from './links';
import PromoFormField from './PromoFormField';

interface LeadershipPrincipalPanelParams extends MultiSelectionFieldParams {
  label: string;
  description?: string;
}

/**
 * Maps the base list of leadership principles from the API to their corresponding `option`
 */
function mapFlatListToSelectOptions(selections: string[] = []) {
  return [...PILLARS, ...LEADERSHIP_PRINCIPLES]
    .filter((item) => selections.includes(item.label))
    .map((item) => ({
      label: item.label,
      value: item.value
    }));
}


/**
 * Generates the list of options for the multi-select control.
 * The structure of the objects changes whether there are suggested items or not.
 * Suggested/recommended items get grouped.
 */
function getOptions(suggestions: string[] | undefined): SelectProps.Options {
  const pillarsOptions = PILLARS.map((pillar) => ({
    label: pillar.label,
    value: pillar.value,
  }));

  const leadershipPrinciplesOptions = LEADERSHIP_PRINCIPLES.map((lp) => ({
    label: lp.label,
    value: lp.value,
  }));

  if (!suggestions) {
    return [
      {
        label: 'Pillars',
        options: pillarsOptions,
      },
      {
        label: 'Leadership Principles',
        options: leadershipPrinciplesOptions,
      },
    ];
  }

  const suggestedOptions = pillarsOptions.filter((option) =>
    suggestions.includes(option.label)
  );

  const otherOptions = leadershipPrinciplesOptions.filter((option) => 
    !suggestions.includes(option.label)
  );

  return [
    {
      label: 'Pillars',
      options: pillarsOptions,
    },
    {
      label: 'Recommended Leadership Principles',
      options: suggestedOptions,
    },
    {
      label: 'Other Leadership Principles',
      options: otherOptions,
    },
  ];
}

const LeadershipPrinciplePanel = ({
  description,
  label,
  onChangeValue,
  onShowTools,
  selections,
  suggestions,
}: LeadershipPrincipalPanelParams): JSX.Element => (
  <Container header={<Header variant="h2">Pillars and Leadership principles</Header>}>
    <PromoFormField
      label={label}
      description={description}
      info={
        <InfoLink
          onShowTools={() => onShowTools?.({ sectionKey: 'leadershipPrinciples' })}
          ariaLabel="Details on Amazon leadership principles."
        />
      }
    >
      <Multiselect
        options={getOptions(suggestions)}
        placeholder="Choose one or more Pillars or LPs"
        filteringType="auto"
        selectedOptions={mapFlatListToSelectOptions(selections)}
        onChange={({ detail }) => {
          console.log(detail.selectedOptions);
          const selectedLabels = detail.selectedOptions.map(option => option.label as string);
          onChangeValue(selectedLabels);
        }}
      />
    </PromoFormField>
  </Container>
);

export default LeadershipPrinciplePanel;
